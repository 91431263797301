import React from 'react'
import {Link} from 'gatsby'
import { FormattedMessage } from "gatsby-plugin-react-intl"
import {StaticImage} from 'gatsby-plugin-image'
import '../assets/thanks.css'

const Thanks = () => {
  return (
    <div className='thanks'>
      <StaticImage
        src='../images/check-tick.png'
        width={100}
       />
      <div className="text">
        <p>
          <FormattedMessage id='thanks.text' />
        </p>
        <Link to='/'>
          <FormattedMessage id="nav.home"/>
        </Link>
      </div>
    </div>
  )
}

export default Thanks
